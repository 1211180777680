import { Client } from './client'
import { User } from './user'
import { AssistanceStatus, AssistanceStatusName } from './assistanceStatus'
import { shallowCopyArray } from '../services/utils'
import { RecurrentFeathersModel } from './RecurrentFeathersModel'
import { AssistantAvailability } from './assistantAvailability'
import { AssistantAssignment, AssistantAssignmentList } from './assistantAssignment'
import { AssistanceStatusManagerService } from '../services/assistance-status-manager.service'
import { AssistantFinancialReport, ClientFinancialReport, FinancialReporting } from './financialReporting'
import { AssistanceHooks } from '../feathersHooks/assistance.hooks'
import { DateRange } from '../services/date-utils.service'
import { addMinutes, differenceInMinutes } from 'date-fns'
import { AssistanceStatusesService } from '../services/domain/codelists/assistance-statuses.service'
import { combineLatest, Observable, of } from 'rxjs'
import { first, map, tap } from 'rxjs/operators'
import { Workgroup } from './workgroup'
import { WorkgroupService } from '../services/domain/workgroup/workgroup.service'
import { CoordinatorService } from '../services/domain/keyCoordinator/coordinator.service'
import { onlyUnique } from '../shared/utils/arrayUtils'
import { ClientService } from '../services/domain/client/client.service'
import { RecurrenceUtil } from '../features/recurrence/RecurrenceUtil'

interface IAssistance {
  id?: number
  clientId?: number
  client?: Client
  keyCoordinatorId?: number
  keyCoordinator?: User

  assistantAssignments?: AssistantAssignmentList

  plannedFrom?: string
  plannedTo?: string

  plannedTimeFrom?
  plannedDateFrom?
  plannedTimeTo?
  plannedDateTo?

  plannedDurationHours?: number
  realizedFrom?: number
  realizedTo?: number
  addressStart?: string
  addressEnd?: string
  statusId?: number | string
  status?: AssistanceStatus
  isSentToClient?: boolean
  coordinatorNote?: string
  shortDescription?: string
  detailedDescription?: string
  isPaidInternally?: boolean
  feedbackFromAssistant?: string
  feedbackFromClient?: string
  isNotToBePrimarilyAssigned?: boolean
  isLateOrder?: boolean
  preferredAssistantGenderId?: number
  strongAssistantNeeded?: boolean
  advancedAssistantNeeded?: boolean
  isSleepover?: boolean
  nonClientWork?: boolean
  isShowInExchange?: boolean

  rrule?: string
  recurrenceException?: string
  recurrenceExceptionDate?: Date
  recurrenceParentId?: number
  recurrenceEnd?: Date
}

export class Assistance extends RecurrentFeathersModel implements IAssistance, FinancialReporting {
  static serviceName = 'assistances'

  id: number
  clientId: number
  bundleParentId?: number
  client: Client
  keyCoordinatorId: number
  keyCoordinator: User

  assistantAssignments: AssistantAssignmentList

  plannedFrom: string // Date.toISOString()
  plannedTo: string // Date.toISOString()

  plannedTimeFrom
  plannedDateFrom
  plannedTimeTo
  plannedDateTo

  plannedDurationHours: number
  plannedDurationMinutes: number
  realizedFrom: number // timestamp
  realizedTo: number // timestamp
  addressStart: string
  addressEnd: string
  statusId: number | string
  status: AssistanceStatus
  isSentToClient: boolean = false
  coordinatorNote: string
  shortDescription: string
  detailedDescription: string
  isPaidInternally: boolean
  feedbackFromClient: string
  isNotToBePrimarilyAssigned: boolean = false
  isLateOrder: boolean = false
  preferredAssistantGenderId: number
  strongAssistantNeeded: boolean = false
  advancedAssistantNeeded: boolean = false
  private oldAssistantAvailability?: AssistantAvailability
  isSleepover: boolean = false
  nonClientWork: boolean = false

  // Only declared property for DTO reasons
  assignments?: any

  // Contract with backend - if this field is true, financial reports are stale and should be updated based on current rates
  backendShouldUpdateFinancialReports: boolean = false
  clientFinancialReports: ClientFinancialReport[]
  assistantFinancialReports: AssistantFinancialReport[]

  isBilled: boolean = false
  isSentSmsNotificationToAssistant: boolean = false
  fieldsChangedSinceLastSentToAssistant: string | null
  isShowInExchange: boolean = false

  private TAG = 'Assistance'

  constructor(statuses?: AssistanceStatus[]) {
    super(null)
    if (statuses) {
      this.initStatus(shallowCopyArray(statuses))
    }
  }

  get realizedDurationHours(): string {
    const minutes = this.realizedDurationMinutes
    if (!minutes) return ''

    return (minutes / 60).toFixed(2)
  }

  get realizedDurationMinutes(): number {
    if (!this.hasRealizedTimes()) return undefined

    const from = new Date(this.realizedFrom)
    const to = new Date(this.realizedTo)
    from.setSeconds(0, 0)
    to.setSeconds(0, 0)

    return differenceInMinutes(to, from)
  }
  set realizedDurationMinutes(value: number) {
    console.log('tried to set realizedDurationMinutes to', value)
  }

  // // RECURRENCE METHODS - TODO should be abstracted somewhere else ideally....
  public static expandAssistancesByRrules(
    assistances: Assistance[],
    range?: DateRange,
    expandParentsAsOccurrences = true,
  ): Assistance[] {
    const expanded: Assistance[] = []

    for (const a of assistances) {
      if (a.rrule) {
        // TODO check for RRULE validity
        expanded.push(...a.expand(range, expandParentsAsOccurrences))
      } else {
        expanded.push(a)
      }
    }

    return expanded
  }
  // // RECURRENCE METHODS end

  updateFromProps(props: IAssistance) {
    const updatedPrimitiveFields: string[] = []

    if (props) {
      Object.keys(props).forEach((key) => {
        if (this[key] !== props[key]) updatedPrimitiveFields.push(key)
        if (props[key] && props[key].length === 0) {
          this[key] = null
        } else {
          this[key] = props[key]
        }
      })
    }

    this.backendShouldUpdateFinancialReports = this.shouldUpdateFinancialReports(updatedPrimitiveFields)
    // console.log(this.TAG, 'backendShouldUpdateFinancialReports', this.backendShouldUpdateFinancialReports)

    if (this.plannedFrom && this.plannedTo) {
      this.plannedDurationMinutes = differenceInMinutes(this.getDateTo(), this.getDateFrom())
    }

    this.turnSubresourcesIntoFrontendModels()
    return this
  }

  updateFromPropsToCreateRecurrenceException(props: IAssistance) {
    props.recurrenceException = null
    props.rrule = null
    return this.updateFromProps(props)
  }

  initPlannedTimes() {
    if (this.plannedFrom) {
      this.plannedTimeFrom = this.plannedFrom
      this.plannedDateFrom = this.plannedFrom
    }
    if (this.plannedTo) {
      this.plannedTimeTo = this.plannedTo
      this.plannedDateTo = this.plannedTo
    }
  }

  public setPlannedTimes(plannedFrom?: string | Date, plannedTo?: string | Date) {
    if (plannedFrom) {
      if (plannedFrom instanceof Date) {
        this.plannedFrom = plannedFrom.toISOString()
      } else {
        this.plannedFrom = plannedFrom
      }
    }

    if (plannedTo) {
      if (plannedTo instanceof Date) {
        this.plannedTo = plannedTo.toISOString()
      } else {
        this.plannedTo = plannedTo
      }
    }

    this.initPlannedTimes()
  }

  updateStatus(status: AssistanceStatus) {
    // console.log('assistance: updateStatus() global to', status.humanReadableName, this)
    this.status = status
    this.statusId = status.id

    if (this.status.name === AssistanceStatusName.CANCELED) {
      this.backendShouldUpdateFinancialReports = true
    }
  }

  public initClientFromClientId(clients: Client[]) {
    console.log('initClientFromClientId', this, clients)
    if (this.clientId && !this.client) {
      this.client = clients.find((c: Client) => {
        return c.id === this.clientId
      })
      console.log('found client', this.client)
      this.initClientDefaults(this.client)
    }

    return this
  }

  public initStatus(statuses: AssistanceStatus[]) {
    if (this.statusId) {
      return this
    }

    const targetStatus = statuses.find((s: AssistanceStatus) => {
      return s.name === AssistanceStatusName.PLANNED
    })
    this.updateStatus(targetStatus)

    return this
  }

  public initClientDefaults(client: Client) {
    console.log('initClientDefaults', client)
    this.keyCoordinator = client.keyCoordinator
    this.keyCoordinatorId = client.keyCoordinatorId

    this.preferredAssistantGenderId = client.preferredGenderId
    this.strongAssistantNeeded = client.needsStrongAssistant
    this.advancedAssistantNeeded = client.needsAdvancedAssistant
  }

  public setClient(client: Client) {
    this.client = client
    this.clientId = client.id
  }
  public setKeyCoordinator(kc: User) {
    this.keyCoordinator = kc
    this.keyCoordinatorId = kc.id
  }

  /**
   * Sets the assignments based on the availabilities.
   * Removes duplicate availabilities and assignments that are already included.
   * Adds new assignments based on the filtered availabilities.
   *
   * @param {AssistantAvailability[]} newAvailabilities - The new availabilities to set assignments from.
   * @param {AssistanceStatusManagerService} assistanceStatusManager - The assistance status manager service.
   * @returns {void}
   */
  public setAssignmentsFromAvailabilities(
    newAvailabilities: AssistantAvailability[],
    assistanceStatusManager: AssistanceStatusManagerService,
  ): void {
    console.log('setAssignmentsFromAvailabilities')
    const currentAssignments = this.assistantAssignments.getAssignmentsPrivateArray()

    // Case re-adding availability which is already there
    // new availability is already included in current assignments, possibly has a status => we should not add it, we should remove it from newAvailabilities
    const filteredNewAvailabilities = newAvailabilities.filter((na) => {
      return !currentAssignments.find((assignment) => {
        const assignmentAvailability = assignment.getAvailability()
        const assignmentAvailabilityId = assignmentAvailability.id
        const assignmentAvailabilityAssistantId = assignmentAvailability.assistant.id

        return na.assistant.id === assignmentAvailabilityAssistantId && na.id === assignmentAvailabilityId
      })
    })
    console.log('setAssignmentsFromAvailabilities after newAvails filter', newAvailabilities)

    // Case removing assignments
    const filteredAssignments = currentAssignments.filter((assignment) => {
      const assignmentAssistantId = assignment.getAvailability().assistant.id
      const assignmentAvailabilityId = assignment.getAvailability().id

      return newAvailabilities.find(
        (na) => na.id === assignmentAvailabilityId && na.assistant.id === assignmentAssistantId,
      )
    })
    console.log('setAssignmentsFromAvailabilities after assignments filter', filteredAssignments)
    this.assistantAssignments.setAssignments(filteredAssignments)

    this.addAssignmentsFromAvailabilities(filteredNewAvailabilities, assistanceStatusManager)
  }

  public addAssignmentsFromAvailabilities(
    availabilities: AssistantAvailability[],
    assistanceStatusManager: AssistanceStatusManagerService,
  ) {
    availabilities.forEach((a) => {
      const assignment = new AssistantAssignment()
      assignment.initStatus(assistanceStatusManager)
      assignment.setAssistantAvailability(a)
      this.assistantAssignments.addUniqueForAssistance(assignment)
    })
  }
  public removeAssignments() {
    this.assistantAssignments.clear()
  }

  getDateFrom(): Date {
    return this.plannedFrom ? new Date(this.plannedFrom) : undefined
  }
  getDateTo(): Date {
    return this.plannedTo ? new Date(this.plannedTo) : undefined
  }
  getRealizedFrom(): Date {
    return this.realizedFrom ? new Date(this.realizedFrom) : undefined
  }
  getRealizedTo(): Date {
    return this.realizedTo ? new Date(this.realizedTo) : undefined
  }
  getRealizedFromWithPlannedFromFallback(): Date {
    if (this.realizedFrom) return new Date(this.realizedFrom)
    if (this.plannedFrom) return new Date(this.plannedFrom)
    return undefined
  }
  getRealizedToWithPlannedToFallback(): Date {
    if (this.realizedTo) return new Date(this.realizedTo)
    if (this.plannedTo) return new Date(this.plannedTo)
    return undefined
  }
  hasRealizedTimes() {
    return !!(this.getRealizedFrom() && this.getRealizedTo())
  }

  turnSubresourcesIntoFrontendModels() {
    if (this.status) {
      this.updateStatus(AssistanceStatus.createFromProps(AssistanceStatus, this.status))
    }
    this.client = this.client ? Client.createFromProps(Client, this.client) : this.client
    this.keyCoordinator = this.keyCoordinator ? User.createFromProps(User, this.keyCoordinator) : this.keyCoordinator

    if (this.assistantFinancialReports) {
      for (let i = 0; i < this.assistantFinancialReports.length; i++) {
        this.assistantFinancialReports[i] = AssistantFinancialReport.createFromProps(
          AssistantFinancialReport,
          this.assistantFinancialReports[i],
        )
      }
    }

    if (this.clientFinancialReports) {
      for (let i = 0; i < this.clientFinancialReports.length; i++) {
        this.clientFinancialReports[i] = ClientFinancialReport.createFromProps(
          ClientFinancialReport,
          this.clientFinancialReports[i],
        )
      }
    }

    AssistanceHooks.convertAssignmentToAssistantAssignmentList(this)
    this.assistantAssignments = AssistantAssignmentList.initializeAsFeathersModels(
      this.assistantAssignments as unknown as any[],
    )
  }

  public isAllowedToBeDeleted() {
    return this.status.name === AssistanceStatusName.PLANNED
  }

  static toListAssistances(
    aa: Assistance[],
    coordinatorService: CoordinatorService,
    workgroupService: WorkgroupService,
    clientsService: ClientService,
    statusService: AssistanceStatusesService,
  ) {
    return combineLatest([
      coordinatorService.getAllWithCaching(),
      workgroupService.getAllWithCaching(),
      clientsService.getAllWithCaching(),
      statusService.getAll(),
    ]).pipe(
      tap((_) => console.log('toListAssistances', aa)),
      map(([allCoordinators, allWorkgroups, allClients, allStatuses]) => {
        console.log(aa)
        return aa.map((a) => new ListAssistance(a, allCoordinators, allWorkgroups, allClients, allStatuses))
      }),
    )
  }

  private shouldUpdateFinancialReports(updatedFieldsKeys: string[]) {
    // console.log(this.TAG, 'shouldUpdateFinancialReports', updatedFieldsKeys)
    if (this.status && this.status.name === AssistanceStatusName.CANCELED_LATE) return true

    if (!this.realizedFrom || !this.realizedTo) return false
    if (this.backendShouldUpdateFinancialReports) return true

    const fieldsToTriggerFRUpdateOnChange = ['realizedFrom', 'realizedTo', 'isSleepover']

    return updatedFieldsKeys.some((r) => fieldsToTriggerFRUpdateOnChange.includes(r))
  }

  public hasAssistant() {
    return this.assistantAssignments.hasAnyAssistant()
  }

  isBundleChild() {
    return !!this.bundleParentId
  }
  // TODO setter for times, where should be included logic for when there are any bundleParent assistances, this assistance cannot have its planned times outside the parent boundaries

  static generateOccurrence(parent: Assistance, startDate: Date, endDate?: Date) {
    const assistance = Assistance.createFromProps(Assistance, parent)
    assistance.id = null

    const resultEndDate = endDate ? endDate : addMinutes(startDate, parent.plannedDurationMinutes)
    assistance.setPlannedTimes(startDate, resultEndDate)

    assistance.realizedFrom = null
    assistance.realizedTo = null

    assistance.recurrenceParentId = parent.id
    assistance.recurrenceException = null
    assistance.recurrenceExceptionDate = new Date(assistance.plannedFrom)
    assistance.rrule = null

    assistance.isSentSmsNotificationToAssistant = false
    assistance.isShowInExchange = false
    assistance.isSentToClient = false
    assistance.fieldsChangedSinceLastSentToAssistant = null
    assistance.isBilled = false

    return assistance
  }

  static generateFirstOccurrence(parent: Assistance) {
    const assistance = Assistance.createFromProps(Assistance, parent)
    assistance.recurrenceParentId = null
    return assistance
  }

  /**
   * Generates URID - unique recurrence ID. This is an ID that can be generated client-side and uniquely identifies an assistance, regardless of whether it already has an assigned ID from database, or is an virtual expanded occurrence from a recurrence parent
   * @param assistance
   * @private
   */
  static generateURID(assistance: Assistance): string {
    return RecurrenceUtil.generateURID(assistance)
  }

  get URIDincludingFirst() {
    return RecurrenceUtil.generateURIDIncludingFirst(this)
  }

  get URID(): string {
    return RecurrenceUtil.generateURID(this)
  }

  addStatusFromId(statusService: AssistanceStatusesService): Observable<this> {
    if (!this.statusId) return of(this).pipe(first())

    return statusService.getById(Number(this.statusId)).pipe(
      map((status) => {
        this.status = status
        return this
      }),
      first(),
    )
  }

  static canBeBilled(realizedFrom: Date, realizedTo: Date, status: AssistanceStatus) {
    return (realizedFrom && realizedTo) || status.name === AssistanceStatusName.CANCELED_LATE
  }

  static canBeDeleted(a: Assistance) {
    return AssistanceStatus.allowsDeletion(a.status)
  }

  /**
   * Check whether assistance can be canceled (moved into CANCELED status)
   */
  static canBeCanceled(realizedFrom: Date, realizedTo: Date, status: AssistanceStatus) {
    return !Assistance.canBeBilled(realizedFrom, realizedTo, status) && AssistanceStatus.allowsCancelation(status)
  }

  generateFirstOccurrence(): Assistance {
    return Assistance.generateFirstOccurrence(this)
  }

  generateOccurrence(occurrenceStartDate: Date, occurrenceEndDate?: Date): Assistance {
    return Assistance.generateOccurrence(this, occurrenceStartDate, occurrenceEndDate)
  }

  static ensureStatusPresentTemporaryCheck(assistances: Assistance[], statusManager: AssistanceStatusManagerService) {
    return assistances.map((a) => {
      if (!a.status) a.status = statusManager.getStatusById(a.statusId)
      return a
    })
  }

  getPreferredAssistantGenderId() {
    if (!this.client) {
      console.error(`${this.TAG}#getPreferredAssistantGenderId: Client should be here!`)
    }
    return this.preferredAssistantGenderId ?? this.client.preferredGenderId
  }
}

export class ListAssistance extends Assistance {
  clientName: string
  keyCoordinatorName: string
  temporaryRealizedFromHhColonMm: string
  temporaryRealizedToHhColonMm: string
  // realizedDurationMinutes: number
  assistantWorkgroups: Workgroup[]

  constructor(
    a: Assistance,
    allCoordinators: User[],
    allWorkgroups: Workgroup[],
    allClients: Client[],
    allStatuses: AssistanceStatus[],
  ) {
    super()

    a.client = allClients.find((c) => c.id === a.clientId)
    a.keyCoordinator = allCoordinators.find((c) => c.id === a.keyCoordinatorId)
    a.status = allStatuses.find((s) => s.id === a.statusId)

    Object.assign(this, a)

    if (a) {
      this.clientName = a.client.user.fullName()
      this.keyCoordinatorName = a.keyCoordinator.fullName()
    }

    if (allCoordinators && allWorkgroups) {
      this.assistantWorkgroups = this.getAssistantWorkgroups(allCoordinators, allWorkgroups)
    }
  }

  getAssistantWorkgroups(allCoordinators: User[], allWorkgroups: Workgroup[]) {
    const keyCoordinatorIds = this.assistantAssignments.getAssistants().map((assistant) => assistant.keyCoordinatorId)
    const assistantCoordinators = keyCoordinatorIds.map((id) => allCoordinators.find((c) => c.id === id))
    return assistantCoordinators.map((coo) => allWorkgroups.find((w) => w.id === coo?.workgroupId))
  }

  get uniqueAssistantWorkgroups(): string {
    return this.assistantWorkgroups
      ?.map((w) => w.name)
      .filter(onlyUnique)
      .join(',')
  }

  static filterByAssistantWorkgroup(assistances: ListAssistance[], wgToFilterBy: Workgroup[]) {
    return assistances.filter((a) => wgToFilterBy.some((fW) => a.assistantWorkgroups.some((aW) => aW.id === fW.id)))
  }
}
