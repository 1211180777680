import { Application, TransportConnection } from '@feathersjs/client'

export const SERVICE_PATHS = {
  ASSIGNMENTS_MANAGER: 'assignments-manager',
  FINANCIAL_REPORTS: 'financial-reports',
  EDITOR_ASSISTANCES: 'editor-assistances'
} as const

const servicesWithCustomMethods = [
  {
    path: SERVICE_PATHS.ASSIGNMENTS_MANAGER,
    methods: [
      'advanceStatus',
      'create',
      'remove',
      'removeAllFromAssistance',
      'advanceAssistanceStatus',
      'cancelAssistance',
    ],
  },
  {
    path: SERVICE_PATHS.FINANCIAL_REPORTS,
    methods: ['find', 'create', 'getSumsForAssistance'],
  },
  {
    path: SERVICE_PATHS.EDITOR_ASSISTANCES,
    methods: [
      'find',
      'get',
      'create',
      'patch',
      'remove',
      'createBundleChild',
      'splitSeries',
    ],
  },
]

export function declareCustomMethodsOnBackendServices(
  client: Application<any, any>,
  socketClient: TransportConnection<any>,
) {
  for (const service of servicesWithCustomMethods) {
    client.use(service.path, socketClient.service(service.path), {
      methods: service.methods,
    })
  }
}
